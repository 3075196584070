import React, { Component } from 'react';
import { connect } from 'react-redux';

import AudioPreviewEventFactory from '../../Events/AudioPreviewEventFactory';
import events from '../../Events/Events';
import {
  selectStockItems,
  selectDrawerIsOpen,
  selectDrawerContentLoaded,
  selectDrawerSelectedItemId,
  selectDrawerType,
  selectSearchFilterOptions,
  selectSearchContext,
  selectCollapsedSetStockItems,
} from '../../app/Search/selectors/searchSelectors';
import { selectIsLoggedIn } from '../../auth/AuthSelectors';
import { contentTypes } from '../../common/Constants';
import { StockItem } from '../../common/types/StockItemTypes';
import { StockItemContext } from '../../common/types/StockItemTypes';
import { WaveformDataInterface } from '../AudioTypes';
import {
  fetchVolumeStateFromSession,
  logAudioPlay,
  saveVolumeStateInSession,
} from '../AudioUtils';
import {
  clearPendingAudioValues,
  pauseAudio,
  playAudio,
  setAudioSource,
  setAudioCurrentVolume,
  setCurrentTime,
  setPendingPlayAudio,
  setPendingCurrentVolume,
  setPendingPastVolume,
  setAudioPastVolume,
} from '../actions/AudioActions';
import {
  selectIsPlaying,
  selectCurrentTime,
  selectCurrentVolume,
  selectAudioSourceUrl,
  selectDuration,
  selectPastVolume,
  selectIsPaused,
  selectAudioPending,
  selectAudioMeta,
  selectAudioFiltersChanged,
  selectAudioContext,
} from '../selectors/audioSelectors';

const PAST_VOLUME = 'pastVolume';
const CURRENT_VOLUME = 'currentVolume';
const REWIND_OR_GO_BACK_THRESHOLD = 3;

type AudioContainerProps = {
  searchStockItems?: StockItem[];
  collapsedSetStockItems?: StockItem[];
  drawerIsOpen?: boolean;
  drawerContentLoaded?: boolean;
  drawerSelectedItemId?: number;
  stockItem?: StockItem;
  filtersChanged?: boolean;
  dispatch?: (any) => void;
  isLoggedIn?: boolean;
  currentTime?: number;
  stockItemArtists?: object[];
  stockItemFormats?: object[];
  audioSourceUrl?: string;
  pendingPlayPrev?: boolean;
  pendingPlayNext?: boolean;
  pendingShouldPause?: boolean;
  pendingShouldPlay?: boolean;
  pendingCurrentTime?: number;
  pendingAudioSourceUrl?: string;
  pendingPastVolume?: number;
  pendingCurrentVolume?: number;
  contentTypeFilter?: string;
  context?: string;
  setPendingCurrentVolume: typeof setPendingCurrentVolume;
  setPendingPastVolume: typeof setPendingPastVolume;
  setCurrentTime: typeof setCurrentTime;
  setAudioSource: typeof setAudioSource;
  playAudio: typeof playAudio;
  pauseAudio: typeof pauseAudio;
  setAudioCurrentVolume: typeof setAudioCurrentVolume;
  setAudioPastVolume: typeof setAudioPastVolume;
  clearPendingAudioValues: typeof clearPendingAudioValues;
  setPendingPlayAudio: typeof setPendingPlayAudio;
};

type AudioContainerState = {
  playStartMs?: number;
  playEndMs?: number;
};

class AudioContainer extends Component<
  AudioContainerProps,
  AudioContainerState
> {
  audio: HTMLAudioElement;

  state = {
    playStartMs: 0,
    playEndMs: 0,
  };

  componentDidMount() {
    // for now, only recall volume from session in search
    if (this.props.context === 'search') {
      const currentVolume = fetchVolumeStateFromSession(CURRENT_VOLUME);
      const pastVolume = fetchVolumeStateFromSession(PAST_VOLUME);

      this.props.setPendingCurrentVolume(currentVolume);
      this.props.setPendingPastVolume(pastVolume);
    }

    this.audio.addEventListener('timeupdate', () => {
      this.setState({ playEndMs: Math.round(this.audio.currentTime * 1000) });
      this.props.setCurrentTime(this.audio.currentTime, this.audio.duration);
    });

    this.audio.addEventListener('loadstart', () => {
      this.props.setAudioSource(this.audio.src);
    });

    this.audio.addEventListener('seeking', () => {
      this.producePreviewEventIfPlaying();
      this.setState({ playStartMs: Math.round(this.audio.currentTime * 1000) });
    });

    this.audio.addEventListener('playing', () => {
      this.props.playAudio();
    });

    this.audio.addEventListener('pause', () => {
      this.producePreviewEventIfPlaying(); // to handle end of play and pause
      this.props.pauseAudio();
    });

    this.audio.addEventListener('ended', () => {
      this.props.contentTypeFilter === contentTypes.MUSIC && this.playNext();
    });

    window.onbeforeunload = () => this.producePreviewEventIfPlaying();
  }

  componentDidUpdate(prevProps, prevState) {
    let hasPendingValues = false;

    if (this.props.pendingCurrentVolume !== null) {
      saveVolumeStateInSession(CURRENT_VOLUME, this.props.pendingCurrentVolume);
      this.props.setAudioCurrentVolume(this.props.pendingCurrentVolume);
      this.audio.volume = this.props.pendingCurrentVolume;

      hasPendingValues = true;
    }

    if (this.props.pendingPastVolume !== null) {
      saveVolumeStateInSession(PAST_VOLUME, this.props.pendingPastVolume);
      this.props.setAudioPastVolume(this.props.pendingPastVolume);

      hasPendingValues = true;
    }

    if (
      this.props.pendingAudioSourceUrl &&
      this.props.audioSourceUrl !== this.props.pendingAudioSourceUrl
    ) {
      this.audio.src = this.props.pendingAudioSourceUrl;
      hasPendingValues = true;
    }

    if (
      this.props.pendingCurrentTime !== null &&
      this.props.pendingCurrentTime >= 0
    ) {
      this.audio.currentTime = this.props.pendingCurrentTime;
      hasPendingValues = true;
    }

    if (this.props.pendingShouldPlay) {
      this.audio.play();
      hasPendingValues = true;
    }

    if (this.props.pendingShouldPause) {
      this.audio.pause();
      hasPendingValues = true;
    }

    if (this.props.pendingPlayNext) {
      this.playNext();
      hasPendingValues = true;
    }

    if (this.props.pendingPlayPrev) {
      this.playPrev();
      hasPendingValues = true;
    }

    if (
      prevProps.stockItem &&
      this.props.stockItem &&
      prevProps.stockItem.id !== this.props.stockItem.id &&
      prevProps.isPlaying !== false
    ) {
      this.producePreviewEventIfPlaying(prevProps, prevState);
    }

    if (hasPendingValues) {
      this.props.clearPendingAudioValues();
    }
  }

  async playNext() {
    const { searchStockItems } = this.props;

    if (searchStockItems.length) {
      const queuedTrack = this.determineQueuedTrack(1);
      await this.playQueuedTrack(queuedTrack);
    }
  }

  async playPrev() {
    const {
      currentTime,
      stockItem,
      stockItemArtists,
      stockItemFormats,
      searchStockItems,
    } = this.props;

    if (searchStockItems.length) {
      const queuedTrack =
        currentTime >= REWIND_OR_GO_BACK_THRESHOLD
          ? // rewind current track and reconstruct from audio state because it may no longer be present in search state
            { stockItem, stockItemArtists, stockItemFormats }
          : this.determineQueuedTrack(-1);

      await this.playQueuedTrack(queuedTrack);
    }
  }

  async playQueuedTrack(queuedTrack) {
    const { isLoggedIn } = this.props;

    let waveformData = {};
    try {
      const waveformResponse = await fetch(queuedTrack.stockItem.waveformUrl);
      waveformData = await waveformResponse.json();
    } catch (e) {
      // just continue for now
    }

    this.props.setPendingPlayAudio(
      queuedTrack.stockItem.previewUrl,
      0,
      queuedTrack.stockItem,
      queuedTrack.stockItemArtists,
      queuedTrack.stockItemFormats,
      waveformData as WaveformDataInterface
    );

    logAudioPlay(queuedTrack.stockItem, isLoggedIn);
  }

  determineQueuedTrack(offset) {
    const { stockItem: currentStockItem, filtersChanged } = this.props;

    // combine search results + the expanded collapsed set if it exists
    const combinedStockItems = this.getCombinedStockItems();

    // if search results updated, start over at the top
    if (filtersChanged) {
      return combinedStockItems[0];
    }

    // find current track in combined list
    const currentIndex = combinedStockItems.findIndex(
      (stockItem) => stockItem.stockItem.id === currentStockItem.id
    );

    // if current track found, determine next track
    if (currentIndex !== -1) {
      // if going back and we're at the top of the list, return the current track to be rewound
      if (offset === -1 && currentIndex === 0) {
        return combinedStockItems[currentIndex];
      }

      const newIndex = currentIndex + offset;
      // if out of bounds, start over at the top
      if (newIndex >= combinedStockItems.length) {
        return combinedStockItems[0];
      }

      return combinedStockItems[newIndex];
    }

    // if current track was not found, find potential parent track in case content was re-collapsed
    const parentIndex = combinedStockItems.findIndex(
      (stockItem) =>
        stockItem.stockItem.collapsedSetId === currentStockItem.collapsedSetId
    );

    // if parentIndex found, determine next track based on that + offset
    if (parentIndex !== -1) {
      const newIndex = parentIndex + offset;
      // if out of bounds, start over at the top
      if (newIndex >= combinedStockItems.length) {
        return combinedStockItems[0];
      }

      return combinedStockItems[newIndex];
    }

    // just start over at the top
    return combinedStockItems[0];
  }

  getCombinedStockItems() {
    const {
      searchStockItems,
      collapsedSetStockItems,
      drawerIsOpen,
      drawerContentLoaded,
      drawerSelectedItemId,
    } = this.props;

    // clone, otherwise splice will mutate the original prop!
    const combinedStockItems = [...searchStockItems];

    // combine search results with collapsed content
    if (drawerIsOpen && drawerContentLoaded && drawerSelectedItemId) {
      const parentIndex = combinedStockItems.findIndex(
        (stockItem) => stockItem.stockItem.id === drawerSelectedItemId
      );
      combinedStockItems.splice(parentIndex + 1, 0, ...collapsedSetStockItems);
    }

    return combinedStockItems;
  }

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <audio id="audio" ref={(audio) => (this.audio = audio)}>
        <source type="audio/mpeg" src={this.props.audioSourceUrl} />
      </audio>
    );
  }

  producePreviewEventIfPlaying = (prevProps = null, prevState = null) => {
    const props = prevProps || this.props;
    const state = prevState || this.state;

    /**
     * The preview event should be fired in the following situations:
     *  - A playing track is paused
     *  - A playing track ends
     *  - A playing track is seeked
     *  - A playing track is changed to another track
     *
     *  The first two are handled by the "pause" media event, and the second by the "playing" media event
     *  (if the current state is playing). The fourth is tricky, and uses the componentDidUpdate hook to see if
     *  stock items were changed.
     */
    const stockItem = props?.stockItem;
    const playStartMs = state?.playStartMs;
    const playEndMs = state?.playEndMs;
    const durationMs = Math.round(props.duration * 1000);
    const playDuration = playEndMs - playStartMs;
    const minPlayDuration = Math.min(durationMs, 1000);
    const stockItemContext = props.stockItemContext;
    const drawerType =
      stockItemContext === StockItemContext.DRAWER ? props.drawerType : null;

    if (
      !props.isPlaying ||
      playStartMs > playEndMs ||
      isNaN(durationMs) ||
      playDuration < minPlayDuration
    ) {
      return;
    }

    try {
      const previewEvent = AudioPreviewEventFactory.create(
        stockItem,
        playStartMs,
        playEndMs,
        durationMs,
        stockItemContext,
        drawerType
      );
      events.produce(previewEvent, previewEvent.user.visitorCookieId);
    } catch (e) {
      // ignore error for now
    }
  };
}

function mapStateToProps(state) {
  const pendingAudio = selectAudioPending(state);
  const { stockItem, stockItemArtists, stockItemFormats } =
    selectAudioMeta(state);

  const selectedSearchFilterOptions = selectSearchFilterOptions(state);

  return {
    isPlaying: selectIsPlaying(state),
    isPaused: selectIsPaused(state),
    audioSourceUrl: selectAudioSourceUrl(state),
    currentTime: selectCurrentTime(state),
    duration: selectDuration(state),
    currentVolume: selectCurrentVolume(state),
    pastVolume: selectPastVolume(state),
    pendingCurrentVolume: pendingAudio.currentVolume,
    pendingPastVolume: pendingAudio.pastVolume,
    pendingMute: pendingAudio.mute,
    pendingCurrentTime: pendingAudio.currentTime,
    pendingAudioSourceUrl: pendingAudio.audioSourceUrl,
    pendingShouldPlay: pendingAudio.play,
    pendingShouldPause: pendingAudio.pause,
    pendingPlayNext: pendingAudio.playNext,
    pendingPlayPrev: pendingAudio.playPrev,
    stockItem,
    stockItemArtists,
    stockItemFormats,
    searchStockItems: selectStockItems(state),
    collapsedSetStockItems: selectCollapsedSetStockItems(state),
    isLoggedIn: selectIsLoggedIn(state),
    drawerIsOpen: selectDrawerIsOpen(state),
    drawerContentLoaded: selectDrawerContentLoaded(state),
    drawerSelectedItemId: selectDrawerSelectedItemId(state),
    drawerType: selectDrawerType(state),
    filtersChanged: selectAudioFiltersChanged(state),
    context: selectSearchContext(state),
    contentTypeFilter: selectedSearchFilterOptions.contentType,
    stockItemContext: selectAudioContext(state),
  };
}

export default connect(mapStateToProps, {
  setPendingCurrentVolume,
  setPendingPastVolume,
  setCurrentTime,
  setAudioSource,
  playAudio,
  pauseAudio,
  setAudioCurrentVolume,
  setAudioPastVolume,
  clearPendingAudioValues,
  setPendingPlayAudio,
  // @ts-ignore
})(AudioContainer);
