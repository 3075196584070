import { AudioPreviewEvent } from '@videoblocks/events-ts/lib/storyblocks/content/AudioPreviewEvent';

import { DrawerType } from '../app/Search/SearchTypes';
import { StockItem, StockItemContext } from '../common/types/StockItemTypes';
import events from './Events';
import ShallowStockItemFactory from './ShallowStockItemFactory';

export default class AudioPreviewEventFactory {
  /**
   * @param {object} stockItem
   * @param {number} playStartMs - Milliseconds offset from beginning of clip clip
   * @param {number} playEndMs - Milliseconds offset from beginning of clip clip
   * @param {number} durationMs
   * @param {StockItemContext} stockItemContext
   * @param {DrawerType} drawerType
   * @returns {AudioPreviewEvent}
   */
  static create(
    stockItem: StockItem,
    playStartMs: number,
    playEndMs: number,
    durationMs: number,
    stockItemContext: StockItemContext,
    drawerType: DrawerType
  ) {
    const event = new AudioPreviewEvent();

    event.meta = events.meta();
    event.device = events.device();
    event.user = events.user();
    event.urls = events.urls();
    event.searchIdentifiers = events.getSearchIdentifiers();

    event.stockItem = ShallowStockItemFactory.create(stockItem);
    event.durationMs = durationMs;
    event.playStartMs = playStartMs;
    event.playEndMs = playEndMs;
    event.stockItemContext = stockItemContext;
    event.drawerType = drawerType;

    return event;
  }
}
